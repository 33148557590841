<template>
  <b-container>
    <div class="kt-login__body">
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>{{ $tc("CLAIM_VALIDATION.PREMIUM.TITLE") }}</h3>
          <span>{{ $tc("CLAIM_VALIDATION.ENTER_DETAILS_BELOW") }}</span>
        </div>

        <b-alert :show="isExpiredLink" variant="danger">{{
          $tc("CLAIM_VALIDATION.ERROR.LINK_EXPIRED")
        }}</b-alert>

        <b-alert :show="isErrorServer" variant="danger">
          {{ $tc("CLAIM_VALIDATION.ERROR.ERROR_SERVER") }}</b-alert
        >

        <b-alert :show="isSuccess" variant="success">{{
          $tc("CLAIM_VALIDATION.SUCCESS.SIGN_IN")
        }}</b-alert>

        <b-form v-if="!isExpiredLink" @submit="onSubmit" class="kt-form">
          <b-form-group
            :state="firstnameState"
            :invalid-feedback="invalidFirstname"
          >
            <b-form-input
              id="input-firstname"
              v-model="firstname"
              type="text"
              :state="firstnameState"
              required
              :placeholder="$tc('CLAIM_VALIDATION.FIRSTNAME')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :state="lastnameState"
            :invalid-feedback="invalidLastname"
          >
            <b-form-input
              id="input-lastname"
              v-model="lastname"
              type="text"
              :state="lastnameState"
              required
              :placeholder="$tc('CLAIM_VALIDATION.LASTNAME')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :state="passwordState"
            :invalid-feedback="invalidPassword"
          >
            <b-form-input
              id="input-password"
              v-model="password"
              type="password"
              :state="passwordState"
              required
              autocomplete="off"
              :placeholder="$tc('CLAIM_VALIDATION.PASSWORD')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :state="confirmPasswordState"
            :invalid-feedback="invalidConfirmPassword"
          >
            <b-form-input
              id="input-confirm-password"
              v-model="confirmPassword"
              type="password"
              :state="confirmPasswordState"
              required
              autocomplete="off"
              :placeholder="$tc('CLAIM_VALIDATION.CONFIRM_PASSWORD')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="mt-4"
            :state="isAgreeComplete"
            :invalid-feedback="isAgreeState"
          >
            <b-form-checkbox
              id="is-agree"
              v-model="isAgree"
              :state="isAgreeComplete"
              value="accepted"
              unchecked-value="not_accepted"
            >
              {{ $tc("CLAIM_VALIDATION.READ_AND_AGREE") }}
              <a href="#" target="_blank">{{
                $tc("CLAIM_VALIDATION.TERMS_AND_CONDITIONS")
              }}</a>
              {{ $tc("CLAIM_VALIDATION.AND") }}
              <a href="#" target="_blank">{{
                $tc("CLAIM_VALIDATION.PRIVACY_POLICY")
              }}</a>
            </b-form-checkbox>
          </b-form-group>

          <div class="mt-5">
            <b-button
              :disabled="isButtonDisabled"
              block
              variant="primary"
              type="submit"
            >
              <template v-if="!isLoading">{{
                $tc("CLAIM_VALIDATION.PREMIUM.TITLE")
              }}</template
              ><b-spinner v-if="isLoading" label="Spinning"></b-spinner
            ></b-button>
          </div>
        </b-form>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { validateToken, setSignup } from "@/api/claim/setters";

import axios from "axios";

export default {
  data() {
    return {
      isCanCheck: false,
      isLoading: false,
      isExpiredLink: false,
      isErrorServer: false,
      isSuccess: false,
      firstname: "",
      lastname: "",
      password: "",
      confirmPassword: "",
      isAgree: ""
    };
  },

  async beforeMount() {
    try {
      await validateToken(this.getParameters, "PREMIUM", {}, this.axios);
    } catch (error) {
      console.error("---SIGNUP---");
      console.error(error);
      const statusCode = error.response.status;
      if (statusCode === 404) {
        this.isExpiredLink = true;
      }
    }
  },

  computed: {
    ...mapGetters(["isAdmin", "isPaidCustomer"]),
    firstnameState() {
      return this.isCanCheck ? this.firstname.length >= 2 : null;
    },

    invalidFirstname() {
      if (this.firstname.length > 0) {
        return this.$tc("CLAIM_VALIDATION.ERROR.MIN_CHARACTERS_2");
      }

      return this.$tc("CLAIM_VALIDATION.ERROR.EMPTY_INPUT");
    },

    lastnameState() {
      return this.isCanCheck ? this.lastname.length >= 2 : null;
    },

    invalidLastname() {
      if (this.firstname.length > 0) {
        return this.$tc("CLAIM_VALIDATION.ERROR.MIN_CHARACTERS_2");
      }

      return this.$tc("CLAIM_VALIDATION.ERROR.EMPTY_INPUT");
    },

    passwordState() {
      return this.isCanCheck
        ? /[A-Z]/.test(this.password) &&
            /[a-z]/.test(this.password) &&
            /[0-9]/.test(this.password) &&
            /[^\w]/.test(this.password) &&
            8 <= this.password.length
        : null;
    },

    confirmPasswordState() {
      return this.isCanCheck
        ? /[A-Z]/.test(this.confirmPassword) &&
            /[a-z]/.test(this.confirmPassword) &&
            /[0-9]/.test(this.confirmPassword) &&
            /[^\w]/.test(this.confirmPassword) &&
            8 <= this.confirmPassword.length
        : null;
    },

    isAgreeState() {
      return this.isCanCheck
        ? this.$tc("CLAIM_VALIDATION.ERROR.EMPTY_CHECKBOX")
        : null;
    },

    invalidPassword() {
      if (this.passwordState) {
        return this.$tc("CLAIM_VALIDATION.ERROR.MIN_CHARACTERS_4");
      }

      if (!this.hasSamePassword) {
        return this.$tc("CLAIM_VALIDATION.ERROR.NOT_IDENTICAL_PASSWORD");
      }

      return this.$tc("CLAIM_VALIDATION.ERROR.ERROR_PASSWORD");
    },

    invalidConfirmPassword() {
      if (this.confirmPasswordState) {
        return this.$tc("CLAIM_VALIDATION.ERROR.MIN_CHARACTERS_4");
      }

      if (!this.hasSamePassword) {
        return this.$tc("CLAIM_VALIDATION.ERROR.NOT_IDENTICAL_PASSWORD");
      }

      return this.$tc("CLAIM_VALIDATION.ERROR.ERROR_PASSWORD");
    },

    isAgreeComplete() {
      return this.isCanCheck ? this.isAgree === "accepted" : null;
    },

    hasSamePassword() {
      return this.isCanCheck ? this.password === this.confirmPassword : null;
    },

    hasError() {
      return (
        !this.firstnameState ||
          !this.lastnameState ||
          !this.passwordState ||
          !this.confirmPasswordState,
        !this.isAgreeComplete
      );
    },

    canSubmit() {
      return !this.hasError;
    },

    getParameters() {
      return `${this.$route.params.claimToken}/${this.$route.params.feedHash}/${this.$route.params.validationKey}`;
    },

    isButtonDisabled() {
      return this.isLoading || this.isSuccess;
    }
  },

  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.isCanCheck = true;
      this.isErrorServer = false;

      if (this.canSubmit) {
        this.isLoading = true;
        try {
          await setSignup(
            this.getParameters,
            "PREMIUM",
            {
              first_name: this.firstname,
              last_name: this.lastname,
              password: this.password,
              confirm_password: this.confirmPassword,
              premiumAccount: true
            },
            axios
          ).then(() => {
            this.isSuccess = true;
            setTimeout(() => {
              this.isLoading = false;
              this.$router.push("/login");
            }, 1000);
          });
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
};
</script>
