import { post } from "../getApi1.0";
import { post as postV2 } from "../getApi2.0";
import { ENTITY_NAME_IN_URI } from "../config/api";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "../config/api2.0";

export const claimPremiumProgram = (axios, claim) =>
  post(
    {
      path: `${ENTITY_NAME_IN_URI.CLAIM.PODCAST.path}/premium/program`
    },
    {},
    claim,
    axios
  );

export const createConfiguration = (axios, confType, data) =>
  post(
    {
      path: `${
        ENTITY_NAME_IN_URI.CONFIGURATIONS[confType.toUpperCase()].path
      }/${confType}`
    },
    {},
    data,
    axios
  );

export const createDomainV2 = (body, axios) =>
  postV2(
    {
      path: ENTITY_NAME_IN_URI_V2.DOMAINS.path
    },
    {},
    body,
    axios
  );

export const setSignup = (value, type, body, axios) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.CLAIM.SIGNUP[type].path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.CLAIM.SIGNUP[type].name,
          value: value
        }
      ]
    },
    {},
    body,
    axios,
    false
  );

export const validateToken = (value, type, body, axios) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.CLAIM.VALIDATION[type].path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.CLAIM.VALIDATION[type].name,
          value: value
        }
      ]
    },
    {},
    body,
    axios,
    false
  );
